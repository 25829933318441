// Request will be proxied via /api/be/[...apiGateway].ts
const baseUrl = "/api/be";

export const BackendApiUrl = {
  test: baseUrl + "/api/test",
  getRoleList: baseUrl + "/api/Dropdown/role-list",
  getUser: baseUrl + "/api/User",
  editUserRole: baseUrl + "/api/User/edit-user-role",
  logUser: baseUrl + "/api/User/manage-log",
  getCategories: baseUrl + "/api/v1/category/get-category",
  deleteUser: baseUrl + "/api/User",
  getChecklists: baseUrl + "/api/v1/checklist/get-checklist",
  getUploadStatus: baseUrl + "/api/Dropdown/upload-status-list",
  updateChecklistUploadStatus:
    baseUrl + "/api/v1/checklist/update-upload-status",
  updateChecklist: baseUrl + "/api/v1/Checklist/update-checklist",
  addChecklist: baseUrl + "/api/v1/Checklist",
  deleteChecklist: baseUrl + "/api/v1/Checklist",
  getChecklistDescription: baseUrl + "/api/v1/Checklist/get-description",
  getHomeSearch: baseUrl + "/api/v1/Home",
  getCategorySearch: baseUrl + "/api/v1/Category/search",
  getSubCategorySearch: baseUrl + "/api/v1/Category/search-second",
  getChecklistSearch: baseUrl + "/api/v1/Checklist/search",
  getChecklistFileSearch: baseUrl + "/api/v1/Checklist/file",

  createSubCategory: baseUrl + "/api/v1/category",
  updateSubCategory: baseUrl + "/api/v1/category",
  deleteSubCategory: baseUrl + "/api/v1/category",
  getSubCategory: baseUrl + "/api/v1/category",
  getChecklistTitle: baseUrl + "/api/v1/category/get-title",
  getProgress: baseUrl + "/api/v1/category/get-progress",
  getSubCategoryList: baseUrl + "/api/Dropdown/sub-category-list",
  uploadFile: baseUrl + "/api/v1/Blob/upload-file",
  uploadFileInformation: baseUrl + "/api/v1/Blob/upload-file-information",
  presignedPutObject: baseUrl + "/api/v1/Blob/presigned-put-object",
  presignedGetObject: baseUrl + "/api/v1/Blob/presigned-get-object",
  saveFile: baseUrl + "/api/v1/Checklist/save-file",
  getInformation: baseUrl + "/api/v1/category/get-chapter",
  getDownloadFile: baseUrl + "/api/v1/Checklist/get-download-file",

  getProcessTypeList: baseUrl + "/api/Dropdown/process-type-list",
  createQuestionTemplate: baseUrl + "/api/v1/Master",
  getQuestionTemplateList: baseUrl + "/api/v1/Master/question-template",
  deleteQuestionTemplate: baseUrl + "/api/v1/Master/delete-question-template",
  updateQuestionTemplate: baseUrl + "/api/v1/Master/update-question-template",
  getQuestionTypeList: baseUrl + "/api/Dropdown/question-type-list",
  createQuestion: baseUrl + "/api/v1/Master/question",
  getQuestionList: baseUrl + "/api/v1/Master/question",
  deleteQuestion: baseUrl + "/api/v1/Master/delete-question",
  updateQuestion: baseUrl + "/api/v1/Master/update-question",
  createUserTemplate: baseUrl + "/api/v1/Master/group-user-template",
  getUserTemplateList: baseUrl + "/api/v1/Master/group-user-template",
  deleteUserTemplateList: baseUrl + "/api/v1/Master/delete-group-user-template",
  updateUserTemplate: baseUrl + "/api/v1/Master/group-user-template",
  getEmailList: baseUrl + "/api/Dropdown/email-list",
  createUsers: baseUrl + "/api/v1/Master/group-user",
  getUsersList: baseUrl + "/api/v1/Master/group-user",
  deleteUsers: baseUrl + "/api/v1/Master/delete-group-user",
  getFilterQuestion: baseUrl + "/api/v1/Master/question-filter",

  getTriaList: baseUrl + "/api/Tria/tria-list",
  getExcelTria: baseUrl + "/api/Tria/download-tria-excel",
  createTriaQuestion: baseUrl + "/api/Tria/create-tria",
  getTriaQuestion: baseUrl + "/api/Tria/tria-question",
  getTriaMasterQuestionLabel: baseUrl + "/api/Tria/tria-master-question",
  getTriaMasterQuestionData: baseUrl + "/api/Tria/tria-master-question-list",
  createTriaRespondent: baseUrl + "/api/Tria/create-respondent",
  getTriaHistory: baseUrl + "/api/Tria/tria-history",

  createPiaReminder: baseUrl + "/api/Pia/create-pia-reminder",
  TriaDetail: baseUrl + "/api/Tria/tria-detail",
  createTriaReminder: baseUrl + "/api/Tria/create-tria-reminder",
  getTriaReminder: baseUrl + "/api/Tria/reminder",
  getIncidentList: baseUrl + "/api/Incident/incident-list",
  getExcelIncident: baseUrl + "/api/Incident/download-incident-excel",
  getDsrList: baseUrl + "/api/Dsr/dsr-list",
  getExcelDsr: baseUrl + "/api/Dsr/download-dsr-excel",
  getPrivacyPolicyList: baseUrl + "/api/PrivacyPolicy/privacy-policy-list",
  getExcelPrivacyPolicy:
    baseUrl + "/api/PrivacyPolicy/download-privacy-policy-excel",
  downloadPrivacyPolicyImage:
    baseUrl + "/api/PrivacyPolicy/download-privacy-policy-image",
  getConsentRecordList: baseUrl + "/api/ConsentRecord/consent-record-list",
  getExcelConsentRecord:
    baseUrl + "/api/ConsentRecord/download-consent-record-excel",
  downloadConsentImage: baseUrl + "/api/ConsentRecord/download-consent-image",
  getDepartments: baseUrl + "/api/Dropdown/departments",
  getIndividualCategories: baseUrl + "/api/Dropdown/individual-categories",
  getPiaStatus: baseUrl + "/api/Dropdown/pia-status",
  getPersonalDataCategories: baseUrl + "/api/Dropdown/personal-data-categories",
  getLawfulBases: baseUrl + "/api/Dropdown/lawful-bases",
  getUsersDropdown: baseUrl + "/api/Dropdown/users",
  getIncidentTypeDropdown: baseUrl + "/api/Dropdown/incident-type",

  getIncidentTypeOptionsDropdown:
    baseUrl + "/api/Dropdown/incident-type-options",
  createIncident: baseUrl + "/api/Incident",
  getReporterIncidentDropdown: baseUrl + "/api/Dropdown/reporter-incident",
  getPicIncidentDropdown: baseUrl + "/api/Dropdown/pic-incident",
  getRecipientCategoriesDropdown:
    baseUrl + "/api/Dropdown/recipient-categories",
  getIncidentDetail: baseUrl + "/api/Incident/incident-detail",
  createIncidentInvestigating: baseUrl + "/api/Incident/incident-investigating",
  createIncidentInvestigatingSubmit:
    baseUrl + "/api/Incident/incident-investigating-submit",
  createIncidentRemdiation: baseUrl + "/api/Incident/incident-remediation",
  createIncidentReminder: baseUrl + "/api/Incident/incident-reminder",
  createIncidentNotification: baseUrl + "/api/Incident/incident-notification",
  getGroupUserList: baseUrl + "/api/Dropdown/group-user-list",
  getRequestTypeList: baseUrl + "/api/Dropdown/request-type-list",
  getRequestTypeOptions: baseUrl + "/api/Dropdown/request-type-options",
  getUserNameList: baseUrl + "/api/Dropdown/user-name-list",
  createDsr: baseUrl + "/api/Dsr/create",
  getDsrDetail: baseUrl + "/api/Dsr/detail",
  createAssignPic: baseUrl + "/api/Dsr/create-assign-pic",
  createDsrVerification: baseUrl + "/api/Dsr/create-verification",
  createDsrProgress: baseUrl + "/api/Dsr/create-progress",
  getRequestNameDropdown: baseUrl + "/api/Dropdown/request-name",
  createDsrReminder: baseUrl + "/api/Dsr/create-reminder",
  getDsrReminder: baseUrl + "/api/Dsr/reminder",
  createDsrStageNotif: baseUrl + "/api/Dsr/dsr-stage-notification",
  getPolicyTypeDropdown: baseUrl + "/api/Dropdown/policy-type",
  getPolicyUploadByDropdown: baseUrl + "/api/Dropdown/policy-upload-by",
  getDataSubjectDropdown: baseUrl + "/api/Dropdown/data-subject-list",
  getConsentUploadByDropdown: baseUrl + "/api/Dropdown/consent-upload-by",
  getNotificationList: baseUrl + "/api/Notification/notification-list",
  deleteNotification: baseUrl + "/api/Notification/delete",
  deleteAllNotification: baseUrl + "/api/Notification/delete-all",
  postReadNotification: baseUrl + "/api/Notification/notification-read",
  uploadConsent: baseUrl + "/api/ConsentRecord/upload-consent",
  uploadPolicy: baseUrl + "/api/PrivacyPolicy/upload-policy",
  getIncidentStagesDropdown: baseUrl + "/api/Dropdown/incident-stages",
  getDsrStagesDropdown: baseUrl + "/api/Dropdown/dsr-stages",
  getConsentFileList: baseUrl + "/api/ConsentRecord/get-consent-file-list",
  getPolicyFileList: baseUrl + "/api/PrivacyPolicy/get-policy-file-list",
  getDataSubjectList: baseUrl + "/api/Dropdown/get-data-subject-list",
  getPolicyTypeList: baseUrl + "/api/Dropdown/get-policy-type-list",
  getFilterPolicy: baseUrl + "/api/PrivacyPolicy/filter-policy",
  GetFilterConsent: baseUrl + "/api/ConsentRecord/filter-consent",
  GetFilterDataMapping: baseUrl + "/api/DataMapping/filter-data-mapping",
  GetFilterIncident: baseUrl + "/api/Incident/filter-incident",
  GetFilterDsr: baseUrl + "/api/Dsr/filter-dsr",
  GetFilterRopa: baseUrl + "/api/Ropa/filter-ropa",
  GetFilterPia: baseUrl + "/api/Pia/filter-pia",
  GetFilterTria: baseUrl + "/api/Tria/filter-tria",
  createPiaRejectConfirm: baseUrl + "/api/Pia/pia-reject-note",
  createPiaStageNotification: baseUrl + "/api/Pia/pia-stage-notification",
  createIncidentStageNotif:
    baseUrl + "/api/Incident/incident-stage-notification",

  //Ropa
  getRopaList: baseUrl + "/api/Ropa/ropa-list",
  getExcelRopa: baseUrl + "/api/Ropa/download-ropa-excel",
  getRopaDetail: baseUrl + "/api/Ropa/detail",
  createRopa: baseUrl + "/api/Ropa/create",
  updateRopa: baseUrl + "/api/Ropa/update",
  deleteRopa: baseUrl + "/api/Ropa/delete",
  getRopaDepartments: baseUrl + "/api/Dropdown/ropa-departments",
  getRopaIndividualCategories:
    baseUrl + "/api/Dropdown/ropa-individual-categories",
  getRopaPersonalDataCategories:
    baseUrl + "/api/Dropdown/ropa-personal-data-categories",
  getRopaLawfulBasis: baseUrl + "/api/Dropdown/ropa-lawful-bases",

  //Data Mapping
  getDataMappingList: baseUrl + "/api/DataMapping/data-mapping-list",
  getExcelDataMapping: baseUrl + "/api/DataMapping/download-data-mapping-excel",
  getDataMappingDetail: baseUrl + "/api/DataMapping/detail",
  getSystemSourcesDropdown:
    baseUrl + "/api/Dropdown/data-mapping/system-sources",
  getDataLifecycleStagesDropdown:
    baseUrl + "/api/Dropdown/data-mapping/data-lifecycle-stages",
  getDmPersonalDataCategoriesDropdown:
    baseUrl + "/api/Dropdown/data-mapping/personal-data-categories",
  getDmIndividualCategoriesDropdown:
    baseUrl + "/api/Dropdown/data-mapping/individual-categories",
  generateDataMapping: baseUrl + "/api/DataMapping/generate-data-mapping",
  generateErrorMessages: baseUrl + "/api/DataMapping/generate-error-messages",
  generateErrorReport: baseUrl + "/api/DataMapping/generate-error-report",

  //Pia
  getPiaList: baseUrl + "/api/Pia/pia-list",
  getExcelPia: baseUrl + "/api/Pia/download-pia-excel",
  getPiaDetail: baseUrl + "/api/Pia/detail",
  getPiaId: baseUrl + "/api/Pia",
  getPiaTask: baseUrl + "/api/Pia/pia-task",

  getPiaHistory: baseUrl + "/api/Pia/pia-history",
  getPiaRespondenDropdown: baseUrl + "/api/Dropdown/pia-responden",
  getPiaReviewerDropdown: baseUrl + "/api/Dropdown/pia-reviewer",
  getPiaApproverDropdown: baseUrl + "/api/Dropdown/pia-approver",
  getPiaApprovalAssessment: baseUrl + "/api/Pia/pia-approval-assessment",
  updatePiaApproval: baseUrl + "/api/Pia/update-pia-approval",
  createPiaRevisionNotes: baseUrl + "/api/Pia/pia-revision-notes",
  getPiaQuestion: baseUrl + "/api/Pia/pia-question",
  getPiaStagesDropdown: baseUrl + "/api/Dropdown/pia-stage",
  createPiaQuestion: baseUrl + "/api/Pia/create-pia",
  PiaDetail: baseUrl + "/api/Pia/pia-detail",
  getMitigationMonitorStatusPia: baseUrl + "/api/Pia/pia-status-mitigation",
  monitorStatusPia: baseUrl + "/api/Pia/pia-mitigation-status",
  getPiaMonitoringAssesment: baseUrl + "/api/Pia/pia-assesment-monitoring",
  submitPiaMonitoring: baseUrl + "/api/Pia/pia-submit-monitoring",
  gePiatMasterQuestionLabel: baseUrl + "/api/Pia/pia-master-question",
  getPiaMasterQuestionData: baseUrl + "/api/Pia/pia-master-question-list",
  getPiaMasterQuestionLabel: baseUrl + "/api/Pia/pia-master-question",
  createPiaRespondent: baseUrl + "/api/Pia/create-respondent",
  getPiaQuestionRecord: baseUrl + "/api/Pia/pia-question-record",
  getPiaReviewRecord: baseUrl + "/api/Pia/pia-review-record",
  getPiaUsersId: baseUrl + "/api/Pia/get-pia-users-id",

  //Tria
  getTriaDetail: baseUrl + "/api/Tria/tria-detail",
  createTriaStageNotification: baseUrl + "/api/Tria/tria-stage-notification",
  getTriaStagesDropdown: baseUrl + "/api/Dropdown/tria-stage",
  getVendorTypesDropdown: baseUrl + "/api/Dropdown/vendor-types",
  getVendorResponsibilityDropdown:
    baseUrl + "/api/Dropdown/vendor-responsibility",
  getTriaRespondenDropdown: baseUrl + "/api/Dropdown/tria-responden",
  getTriaReviewerDropdown: baseUrl + "/api/Dropdown/tria-reviewer",
  getTriaApproverDropdown: baseUrl + "/api/Dropdown/tria-approver",
  getTriaApprovalAssessment: baseUrl + "/api/Tria/tria-approval-assessment",
  updateTriaApproval: baseUrl + "/api/Tria/update-tria-approval",
  createTriaRevisionNote: baseUrl + "/api/Tria/tria-revision-notes",
  getTriaMonitoringAssesment: baseUrl + "/api/Tria/tria-asessment-monitoring",
  getMitigationMonitorStatusTria: baseUrl + "/api/Tria/tria-status-mitigation",
  submitTriaMonitoring: baseUrl + "/api/Tria/tria-monitoring-submit",
  monitorStatusTria: baseUrl + "/api/Tria/tria-monitoring-status",
  getTriaUsersId: baseUrl + "/api/Tria/get-tria-users-id",
  createTriaRejectConfirm: baseUrl + "/api/Tria/tria-reject-note",

  getVendorList: baseUrl + "/api/v1/Master/vendor-list",
  getVendorDetail: baseUrl + "/api/v1/Master/vendor-detail",
  postVendor: baseUrl + "/api/v1/Master/create-vendor",
  deleteVendor: baseUrl + "/api/v1/Master/delete-vendor",
  putVendor: baseUrl + "/api/v1/Master/update-vendor",

  postTriaUnderReviewAssessmentAnswers:
    baseUrl + "/api/Tria/under-review-assessment-answers",
  getRiskCategories: baseUrl + "/api/Dropdown/risk-categories",
  getTriaQuestionRecord: baseUrl + "/api/Tria/tria-question-record",
  getTriaReviewRecord: baseUrl + "/api/Tria/tria-review-record",
  getTriaProgressTask: baseUrl + "/api/Tria/progress-task",
  getPiaProgressTask: baseUrl + "/api/Pia/progress-task",
  postPiaUnderReviewAssessmentAnswer:
    baseUrl + "/api/Pia/under-review-assessment-answers",

  uploadUserData: baseUrl + "/api/v1/Master/upload-data-user",
  generateUserErrorReport: baseUrl + "/api/v1/Master/generate-error-report",
};

export function GetLog(itemsPerPage: number, page: number, search: string) {
  const param = new URLSearchParams();
  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }
  if (search !== undefined) {
    param.append("search", search);
  }
  return BackendApiUrl.logUser + "?" + param.toString();
}

export function GetCategoryDetail(categoryId: string) {
  const url = baseUrl + `/api/v1/category/${categoryId}`;

  return url;
}

export function GetChecklistList(verseId: string) {
  const param = new URLSearchParams();

  param.append("verseId", verseId.toString());

  return BackendApiUrl.getChecklists + "?" + param.toString();
}

export function GetChecklistTitle(verseId: string) {
  const param = new URLSearchParams();

  param.append("verseId", verseId.toString());

  return BackendApiUrl.getChecklistTitle + "?" + param.toString();
}

export function GetChecklistDescription(verseId: string) {
  const param = new URLSearchParams();

  param.append("verseId", verseId.toString());

  return BackendApiUrl.getChecklistDescription + "?" + param.toString();
}

export function GetInformation(categoryId: string) {
  const param = new URLSearchParams();

  param.append("categoryId", categoryId.toString());

  return BackendApiUrl.getInformation + "?" + param.toString();
}
export function GetProgress(categoryId: string) {
  const param = new URLSearchParams();

  param.append("categoryId", categoryId.toString());

  return BackendApiUrl.getProgress + "?" + param.toString();
}

export function GetQuestionTemplate(questionName: string, processId: string) {
  const param = new URLSearchParams();
  if (questionName !== "") {
    param.append("questionName", questionName);
  }
  if (processId !== "") {
    param.append("processId", processId);
  }
  return BackendApiUrl.getQuestionTemplateList + "?" + param.toString();
}

export function GetQuestion(
  question: string,
  QuestionTypeId: string,
  id: string
) {
  const param = new URLSearchParams();
  param.append("id", id);
  if (question !== "") {
    param.append("question", question);
  }
  if (QuestionTypeId !== "") {
    param.append("QuestionTypeId", QuestionTypeId);
  }
  return BackendApiUrl.getQuestionList + "?" + param.toString();
}

export function GetFilterQuestion(question: string, questionTypeId: string) {
  const param = new URLSearchParams();
  if (question !== undefined) {
    param.append("question", question.toLocaleString());
  }
  if (questionTypeId !== undefined) {
    param.append("questionTypeId", questionTypeId.toLocaleString());
  }
  return BackendApiUrl.getFilterQuestion + "?" + param.toString();
}

export function GetUserTemplate(groupUserName: string, description: string) {
  const param = new URLSearchParams();
  if (groupUserName !== "") {
    param.append("groupUserName", groupUserName);
  }
  if (description !== "") {
    param.append("description", description);
  }
  return BackendApiUrl.getUserTemplateList + "?" + param.toString();
}

export function GetUsers(email: string[] = [], id: string) {
  const param = new URLSearchParams();
  param.append("id", id);
  if (email !== undefined) {
    email.forEach((email) => {
      param.append("email", email);
    });
  }
  return BackendApiUrl.getUsersList + "?" + param.toString();
}

export function GetFilterEmail(email: string[] = [], id: string) {
  const param = new URLSearchParams();
  param.append("id", id);
  if (email !== undefined) {
    email.forEach((email) => {
      param.append("email", email);
    });
  }
  return BackendApiUrl.getEmailList + "?" + param.toString();
}

export function GetRopaList(
  itemsPerPage: number,
  page: number,
  activityName: string,
  departmentId: string,
  dataOwner: string,
  processingPurpose: string,
  personalDataCategoryId: string,
  individualCategoryId: string,
  lawfulBaseId: string
) {
  const param = new URLSearchParams();
  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }
  if (activityName !== undefined) {
    param.append("activityName", activityName);
  }
  if (departmentId !== undefined) {
    param.append("departmentId", departmentId);
  }
  if (dataOwner !== undefined) {
    param.append("dataOwner", dataOwner);
  }
  if (processingPurpose !== undefined) {
    param.append("processingPurpose", processingPurpose);
  }
  if (personalDataCategoryId !== undefined) {
    param.append("personalDataCategoryId", personalDataCategoryId);
  }
  if (individualCategoryId !== undefined) {
    param.append("individualCategoryId", individualCategoryId);
  }
  if (lawfulBaseId !== undefined) {
    param.append("lawfulBaseId", lawfulBaseId);
  }

  return BackendApiUrl.getRopaList + "?" + param.toString();
}

export function GetFilterRopa(
  activityName: string,
  departmentId: string,
  dataOwner: string,
  processingPurpose: string,
  personalDataCategoryId: string,
  individualCategoryId: string,
  lawfulBaseId: string
) {
  const param = new URLSearchParams();
  if (activityName !== undefined) {
    param.append("activityName", activityName);
  }
  if (departmentId !== undefined) {
    param.append("departmentId", departmentId);
  }
  if (dataOwner !== undefined) {
    param.append("dataOwner", dataOwner);
  }
  if (processingPurpose !== undefined) {
    param.append("processingPurpose", processingPurpose);
  }
  if (personalDataCategoryId !== undefined) {
    param.append("personalDataCategoryId", personalDataCategoryId);
  }
  if (individualCategoryId !== undefined) {
    param.append("individualCategoryId", individualCategoryId);
  }
  if (lawfulBaseId !== undefined) {
    param.append("lawfulBaseId", lawfulBaseId);
  }

  return BackendApiUrl.GetFilterRopa + "?" + param.toString();
}

export function GetExcelRopa(
  activityName: string,
  departmentId: string,
  dataOwner: string,
  processingPurpose: string,
  personalDataCategoryId: string,
  individualCategoryId: string,
  lawfulBaseId: string
) {
  const param = new URLSearchParams();
  if (activityName !== undefined) {
    param.append("activityName", activityName);
  }
  if (departmentId !== undefined) {
    param.append("departmentId", departmentId);
  }
  if (dataOwner !== undefined) {
    param.append("dataOwner", dataOwner);
  }
  if (processingPurpose !== undefined) {
    param.append("processingPurpose", processingPurpose);
  }
  if (personalDataCategoryId !== undefined) {
    param.append("personalDataCategoryId", personalDataCategoryId);
  }
  if (individualCategoryId !== undefined) {
    param.append("individualCategoryId", individualCategoryId);
  }
  if (lawfulBaseId !== undefined) {
    param.append("lawfulBaseId", lawfulBaseId);
  }

  return BackendApiUrl.getExcelRopa + "?" + param.toString();
}

export function GetPiaList(
  itemsPerPage: number,
  page: number,
  activityName: string,
  departmentId: string,
  respondenId: string,
  reviewerId: string,
  approverId: string,
  personalDataCategoryId: string,
  piaStatusIds: string[] = [],
  individualCategoryId: string,
  lawfulBaseId: string,
  startDateBegin: string,
  startDateEnded: string,
  endDateBegin: string,
  endDateEnded: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }
  if (activityName !== undefined) {
    param.append("activityName", activityName);
  }
  if (departmentId !== undefined) {
    param.append("departmentId", departmentId);
  }
  if (respondenId !== undefined) {
    param.append("respondenId", respondenId);
  }
  if (reviewerId !== undefined) {
    param.append("reviewerId", reviewerId);
  }
  if (approverId !== undefined) {
    param.append("approverId", approverId);
  }
  if (piaStatusIds !== undefined) {
    piaStatusIds.forEach((piaStatusId) => {
      param.append("piaStatusIds", piaStatusId);
    });
  }
  if (personalDataCategoryId !== undefined) {
    param.append("personalDataCategoryId", personalDataCategoryId);
  }
  if (individualCategoryId !== undefined) {
    param.append("individualCategoryId", individualCategoryId);
  }
  if (lawfulBaseId !== undefined) {
    param.append("lawfulBaseId", lawfulBaseId);
  }
  if (startDateBegin !== undefined) {
    param.append("startDateBegin", startDateBegin);
  }
  if (startDateEnded !== undefined) {
    param.append("startDateEnded", startDateEnded);
  }
  if (endDateBegin !== undefined) {
    param.append("endDateBegin", endDateBegin);
  }
  if (endDateEnded !== undefined) {
    param.append("endDateEnded", endDateEnded);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.getPiaList + "?" + param.toString();
}

export function GetFilterPia(
  activityName: string,
  departmentId: string,
  respondenId: string,
  reviewerId: string,
  approverId: string,
  personalDataCategoryId: string,
  piaStatusIds: string[] = [],
  individualCategoryId: string,
  lawfulBaseId: string,
  startDateBegin: string,
  startDateEnded: string,
  endDateBegin: string,
  endDateEnded: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (activityName !== undefined) {
    param.append("activityName", activityName);
  }
  if (departmentId !== undefined) {
    param.append("departmentId", departmentId);
  }
  if (respondenId !== undefined) {
    param.append("respondenId", respondenId);
  }
  if (reviewerId !== undefined) {
    param.append("reviewerId", reviewerId);
  }
  if (approverId !== undefined) {
    param.append("approverId", approverId);
  }
  if (piaStatusIds !== undefined) {
    piaStatusIds.forEach((piaStatusId) => {
      param.append("piaStatusIds", piaStatusId);
    });
  }
  if (personalDataCategoryId !== undefined) {
    param.append("personalDataCategoryId", personalDataCategoryId);
  }
  if (individualCategoryId !== undefined) {
    param.append("individualCategoryId", individualCategoryId);
  }
  if (lawfulBaseId !== undefined) {
    param.append("lawfulBaseId", lawfulBaseId);
  }
  if (startDateBegin !== undefined) {
    param.append("startDateBegin", startDateBegin);
  }
  if (startDateEnded !== undefined) {
    param.append("startDateEnded", startDateEnded);
  }
  if (endDateBegin !== undefined) {
    param.append("endDateBegin", endDateBegin);
  }
  if (endDateEnded !== undefined) {
    param.append("endDateEnded", endDateEnded);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.GetFilterPia + "?" + param.toString();
}

export function GetExcelPia(
  activityName: string,
  departmentId: string,
  respondenId: string,
  reviewerId: string,
  approverId: string,
  personalDataCategoryId: string,
  piaStatusIds: string[] = [],
  individualCategoryId: string,
  lawfulBaseId: string,
  startDateBegin: string,
  startDateEnded: string,
  endDateBegin: string,
  endDateEnded: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (activityName !== undefined) {
    param.append("activityName", activityName);
  }
  if (departmentId !== undefined) {
    param.append("departmentId", departmentId);
  }
  if (respondenId !== undefined) {
    param.append("respondenId", respondenId);
  }
  if (reviewerId !== undefined) {
    param.append("reviewerId", reviewerId);
  }
  if (approverId !== undefined) {
    param.append("approverId", approverId);
  }
  if (piaStatusIds !== undefined) {
    piaStatusIds.forEach((piaStatusId) => {
      param.append("piaStatusIds", piaStatusId);
    });
  }
  if (personalDataCategoryId !== undefined) {
    param.append("personalDataCategoryId", personalDataCategoryId);
  }
  if (individualCategoryId !== undefined) {
    param.append("individualCategoryId", individualCategoryId);
  }
  if (lawfulBaseId !== undefined) {
    param.append("lawfulBaseId", lawfulBaseId);
  }
  if (startDateBegin !== undefined) {
    param.append("startDateBegin", startDateBegin);
  }
  if (startDateEnded !== undefined) {
    param.append("startDateEnded", startDateEnded);
  }
  if (endDateBegin !== undefined) {
    param.append("endDateBegin", endDateBegin);
  }
  if (endDateEnded !== undefined) {
    param.append("endDateEnded", endDateEnded);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.getExcelPia + "?" + param.toString();
}

export function GetTriaList(
  itemsPerPage: number,
  page: number,
  vendorName: string,
  respondenId: string,
  reviewerId: string,
  approverId: string,
  triaStatusIds: string[] = [],
  vendorTypeId: string,
  noSiup: string,
  vendorResponsibility: string,
  startDateBegin: string,
  startDateEnded: string,
  endDateBegin: string,
  endDateEnded: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }
  if (vendorName !== undefined) {
    param.append("vendorName", vendorName);
  }
  if (respondenId !== undefined) {
    param.append("respondenId", respondenId);
  }
  if (reviewerId !== undefined) {
    param.append("reviewerId", reviewerId);
  }
  if (approverId !== undefined) {
    param.append("approverId", approverId);
  }
  if (triaStatusIds !== undefined) {
    triaStatusIds.forEach((triaStatusId) => {
      param.append("triaStatusIds", triaStatusId);
    });
  }
  if (vendorTypeId !== undefined) {
    param.append("vendorTypeId", vendorTypeId);
  }
  if (noSiup !== undefined) {
    param.append("noSiup", noSiup);
  }
  if (vendorResponsibility !== undefined) {
    param.append("vendorResponsibility", vendorResponsibility);
  }
  if (startDateBegin !== undefined) {
    param.append("startDateBegin", startDateBegin);
  }
  if (startDateEnded !== undefined) {
    param.append("startDateEnded", startDateEnded);
  }
  if (endDateBegin !== undefined) {
    param.append("endDateBegin", endDateBegin);
  }
  if (endDateEnded !== undefined) {
    param.append("endDateEnded", endDateEnded);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.getTriaList + "?" + param.toString();
}

export function GetFilterTria(
  vendorName: string,
  respondenId: string,
  reviewerId: string,
  approverId: string,
  triaStatusIds: string[] = [],
  vendorTypeId: string,
  noSiup: string,
  vendorResponsibility: string,
  startDateBegin: string,
  startDateEnded: string,
  endDateBegin: string,
  endDateEnded: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (vendorName !== undefined) {
    param.append("vendorName", vendorName);
  }
  if (respondenId !== undefined) {
    param.append("respondenId", respondenId);
  }
  if (reviewerId !== undefined) {
    param.append("reviewerId", reviewerId);
  }
  if (approverId !== undefined) {
    param.append("approverId", approverId);
  }
  if (triaStatusIds !== undefined) {
    triaStatusIds.forEach((triaStatusId) => {
      param.append("triaStatusIds", triaStatusId);
    });
  }
  if (vendorTypeId !== undefined) {
    param.append("vendorTypeId", vendorTypeId);
  }
  if (noSiup !== undefined) {
    param.append("noSiup", noSiup);
  }
  if (vendorResponsibility !== undefined) {
    param.append("vendorResponsibility", vendorResponsibility);
  }
  if (startDateBegin !== undefined) {
    param.append("startDateBegin", startDateBegin);
  }
  if (startDateEnded !== undefined) {
    param.append("startDateEnded", startDateEnded);
  }
  if (endDateBegin !== undefined) {
    param.append("endDateBegin", endDateBegin);
  }
  if (endDateEnded !== undefined) {
    param.append("endDateEnded", endDateEnded);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.GetFilterTria + "?" + param.toString();
}

export function GetExcelTria(
  vendorName: string,
  respondenId: string,
  reviewerId: string,
  approverId: string,
  triaStatusIds: string[] = [],
  vendorTypeId: string,
  noSiup: string,
  vendorResponsibility: string,
  startDateBegin: string,
  startDateEnded: string,
  endDateBegin: string,
  endDateEnded: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (vendorName !== undefined) {
    param.append("vendorName", vendorName);
  }
  if (respondenId !== undefined) {
    param.append("respondenId", respondenId);
  }
  if (reviewerId !== undefined) {
    param.append("reviewerId", reviewerId);
  }
  if (approverId !== undefined) {
    param.append("approverId", approverId);
  }
  if (triaStatusIds !== undefined) {
    triaStatusIds.forEach((triaStatusId) => {
      param.append("triaStatusIds", triaStatusId);
    });
  }
  if (vendorTypeId !== undefined) {
    param.append("vendorTypeId", vendorTypeId);
  }
  if (noSiup !== undefined) {
    param.append("noSiup", noSiup);
  }
  if (vendorResponsibility !== undefined) {
    param.append("vendorResponsibility", vendorResponsibility);
  }
  if (startDateBegin !== undefined) {
    param.append("startDateBegin", startDateBegin);
  }
  if (startDateEnded !== undefined) {
    param.append("startDateEnded", startDateEnded);
  }
  if (endDateBegin !== undefined) {
    param.append("endDateBegin", endDateBegin);
  }
  if (endDateEnded !== undefined) {
    param.append("endDateEnded", endDateEnded);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.getExcelTria + "?" + param.toString();
}

export function GetIncidentList(
  itemsPerPage: number,
  page: number,
  incidentTypeId: string,
  reportBy: string,
  incidentStageIds: string[] = [],
  picNameId: string,
  reportDateBegin: string,
  reportDateEnded: string,
  dueDate: string,
  overDue: string,
  incidentDetail: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }
  if (incidentTypeId !== undefined) {
    param.append("incidentTypeId", incidentTypeId);
  }
  if (reportBy !== undefined) {
    param.append("reportBy", reportBy);
  }
  if (incidentStageIds !== undefined) {
    incidentStageIds.forEach((incidentStageId) => {
      param.append("incidentStageIds", incidentStageId);
    });
  }
  if (picNameId !== undefined) {
    param.append("picNameId", picNameId);
  }
  if (reportDateBegin !== undefined) {
    param.append("reportDateBegin", reportDateBegin);
  }
  if (reportDateEnded !== undefined) {
    param.append("reportDateEnded", reportDateEnded);
  }
  if (dueDate !== undefined) {
    param.append("dueDate", dueDate);
  }
  if (overDue !== undefined) {
    param.append("overDue", overDue);
  }
  if (incidentDetail !== undefined) {
    param.append("incidentDetail", incidentDetail);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.getIncidentList + "?" + param.toString();
}

export function GetFilterIncident(
  incidentTypeId: string,
  reportBy: string,
  incidentStageIds: string[] = [],
  picName: string,
  reportDateBegin: string,
  reportDateEnded: string,
  dueDate: string,
  overDue: string,
  incidentDetail: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (incidentTypeId !== undefined) {
    param.append("incidentTypeId", incidentTypeId);
  }
  if (reportBy !== undefined) {
    param.append("reportBy", reportBy);
  }
  if (incidentStageIds !== undefined) {
    incidentStageIds.forEach((incidentStageId) => {
      param.append("incidentStageIds", incidentStageId);
    });
  }
  if (picName !== undefined) {
    param.append("picName", picName);
  }
  if (reportDateBegin !== undefined) {
    param.append("reportDateBegin", reportDateBegin);
  }
  if (reportDateEnded !== undefined) {
    param.append("reportDateEnded", reportDateEnded);
  }
  if (dueDate !== undefined) {
    param.append("dueDate", dueDate);
  }
  if (overDue !== undefined) {
    param.append("overDue", overDue);
  }
  if (incidentDetail !== undefined) {
    param.append("incidentDetail", incidentDetail);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.GetFilterIncident + "?" + param.toString();
}

export function GetExcelIncident(
  incidentTypeId: string,
  reportBy: string,
  incidentStageIds: string[] = [],
  picNameId: string,
  reportDateBegin: string,
  reportDateEnded: string,
  dueDate: string,
  overDue: string,
  incidentDetail: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (incidentTypeId !== undefined) {
    param.append("incidentTypeId", incidentTypeId);
  }
  if (reportBy !== undefined) {
    param.append("reportBy", reportBy);
  }
  if (incidentStageIds !== undefined) {
    incidentStageIds.forEach((incidentStageId) => {
      param.append("incidentStageIds", incidentStageId);
    });
  }
  if (picNameId !== undefined) {
    param.append("picNameId", picNameId);
  }
  if (reportDateBegin !== undefined) {
    param.append("reportDateBegin", reportDateBegin);
  }
  if (reportDateEnded !== undefined) {
    param.append("reportDateEnded", reportDateEnded);
  }
  if (dueDate !== undefined) {
    param.append("dueDate", dueDate);
  }
  if (overDue !== undefined) {
    param.append("overDue", overDue);
  }
  if (incidentDetail !== undefined) {
    param.append("incidentDetail", incidentDetail);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.getExcelIncident + "?" + param.toString();
}

export function GetDsrList(
  itemsPerPage: number,
  page: number,
  requestTypeId: string,
  requesterName: string,
  requestStageIds: string[] = [],
  requestDateBegin: string,
  requestDateEnded: string,
  completionDateBegin: string,
  completionDateEnded: string,
  dueDate: string,
  overDue: string,
  requestDetail: string,
  actionDetail: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }
  if (requestTypeId !== undefined) {
    param.append("requestTypeId", requestTypeId);
  }
  if (requesterName !== undefined) {
    param.append("requesterName", requesterName);
  }
  if (requestStageIds !== undefined) {
    requestStageIds.forEach((stageId) => {
      param.append("requestStageIds", stageId);
    });
  }
  if (requestDateBegin !== undefined) {
    param.append("requestDateBegin", requestDateBegin);
  }
  if (requestDateEnded !== undefined) {
    param.append("requestDateEnded", requestDateEnded);
  }
  if (completionDateBegin !== undefined) {
    param.append("completionDateBegin", completionDateBegin);
  }
  if (completionDateEnded !== undefined) {
    param.append("completionDateEnded", completionDateEnded);
  }
  if (dueDate !== undefined) {
    param.append("dueDate", dueDate);
  }
  if (overDue !== undefined) {
    param.append("overDue", overDue);
  }
  if (requestDetail !== undefined) {
    param.append("requestDetail", requestDetail);
  }
  if (actionDetail !== undefined) {
    param.append("actionDetail", actionDetail);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.getDsrList + "?" + param.toString();
}

export function GetFilterDsr(
  requestTypeId: string,
  requesterName: string,
  requestStageIds: string[] = [],
  requestDateBegin: string,
  requestDateEnded: string,
  completionDateBegin: string,
  completionDateEnded: string,
  dueDate: string,
  overDue: string,
  requestDetail: string,
  actionDetail: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (requestTypeId !== undefined) {
    param.append("requestTypeId", requestTypeId);
  }
  if (requesterName !== undefined) {
    param.append("requesterName", requesterName);
  }
  if (requestStageIds !== undefined) {
    requestStageIds.forEach((stageId) => {
      param.append("requestStageIds", stageId);
    });
  }
  if (requestDateBegin !== undefined) {
    param.append("requestDateBegin", requestDateBegin);
  }
  if (requestDateEnded !== undefined) {
    param.append("requestDateEnded", requestDateEnded);
  }
  if (completionDateBegin !== undefined) {
    param.append("completionDateBegin", completionDateBegin);
  }
  if (completionDateEnded !== undefined) {
    param.append("completionDateEnded", completionDateEnded);
  }
  if (dueDate !== undefined) {
    param.append("dueDate", dueDate);
  }
  if (overDue !== undefined) {
    param.append("overDue", overDue);
  }
  if (requestDetail !== undefined) {
    param.append("requestDetail", requestDetail);
  }
  if (actionDetail !== undefined) {
    param.append("actionDetail", actionDetail);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.GetFilterDsr + "?" + param.toString();
}

export function GetExcelDsr(
  requestTypeId: string,
  requesterName: string,
  requestStageIds: string[] = [],
  requestDateBegin: string,
  requestDateEnded: string,
  completionDateBegin: string,
  completionDateEnded: string,
  dueDate: string,
  overDue: string,
  requestDetail: string,
  actionDetail: string,
  isAdmin: boolean
) {
  const param = new URLSearchParams();
  if (requestTypeId !== undefined) {
    param.append("requestTypeId", requestTypeId);
  }
  if (requesterName !== undefined) {
    param.append("requesterName", requesterName);
  }
  if (requestStageIds !== undefined) {
    requestStageIds.forEach((stageId) => {
      param.append("requestStageIds", stageId);
    });
  }
  if (requestDateBegin !== undefined) {
    param.append("requestDateBegin", requestDateBegin);
  }
  if (requestDateEnded !== undefined) {
    param.append("requestDateEnded", requestDateEnded);
  }
  if (completionDateBegin !== undefined) {
    param.append("completionDateBegin", completionDateBegin);
  }
  if (completionDateEnded !== undefined) {
    param.append("completionDateEnded", completionDateEnded);
  }
  if (dueDate !== undefined) {
    param.append("dueDate", dueDate);
  }
  if (overDue !== undefined) {
    param.append("overDue", overDue);
  }
  if (requestDetail !== undefined) {
    param.append("requestDetail", requestDetail);
  }
  if (actionDetail !== undefined) {
    param.append("actionDetail", actionDetail);
  }
  if (isAdmin !== undefined) {
    param.append("isAdmin", isAdmin.toString());
  }

  return BackendApiUrl.getExcelDsr + "?" + param.toString();
}

export function GetPrivacyPolicyList(
  itemsPerPage: number,
  page: number,
  policyName: string,
  policyTypeId: string,
  uploadBy: string,
  uploadAtBegin: string,
  uploadAtEnded: string
) {
  const param = new URLSearchParams();
  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }
  if (policyName !== undefined) {
    param.append("policyName", policyName);
  }
  if (policyTypeId !== undefined) {
    param.append("policyTypeId", policyTypeId);
  }
  if (uploadAtBegin !== undefined) {
    param.append("uploadAtBegin", uploadAtBegin);
  }
  if (uploadBy !== undefined) {
    param.append("uploadBy", uploadBy);
  }
  if (uploadAtEnded !== undefined) {
    param.append("uploadAtEnded", uploadAtEnded);
  }

  return BackendApiUrl.getPrivacyPolicyList + "?" + param.toString();
}

export function GetFilterPolicy(
  policyName: string,
  policyTypeId: string,
  uploadBy: string,
  uploadAtBegin: string,
  uploadAtEnded: string
) {
  const param = new URLSearchParams();
  if (policyName !== undefined) {
    param.append("policyName", policyName);
  }
  if (policyTypeId !== undefined) {
    param.append("policyTypeId", policyTypeId);
  }
  if (uploadAtBegin !== undefined) {
    param.append("uploadAtBegin", uploadAtBegin);
  }
  if (uploadBy !== undefined) {
    param.append("uploadBy", uploadBy);
  }
  if (uploadAtEnded !== undefined) {
    param.append("uploadAtEnded", uploadAtEnded);
  }

  return BackendApiUrl.getFilterPolicy + "?" + param.toString();
}

export function GetExcelPrivacyPolicy(
  policyName: string,
  policyTypeId: string,
  uploadBy: string,
  uploadAtBegin: string,
  uploadAtEnded: string
) {
  const param = new URLSearchParams();
  if (policyName !== undefined) {
    param.append("policyName", policyName);
  }
  if (policyTypeId !== undefined) {
    param.append("policyTypeId", policyTypeId);
  }
  if (uploadAtBegin !== undefined) {
    param.append("uploadAtBegin", uploadAtBegin);
  }
  if (uploadBy !== undefined) {
    param.append("uploadBy", uploadBy);
  }
  if (uploadAtEnded !== undefined) {
    param.append("uploadAtEnded", uploadAtEnded);
  }

  return BackendApiUrl.getExcelPrivacyPolicy + "?" + param.toString();
}

export function GetConsentRecordList(
  itemsPerPage: number,
  page: number,
  name: string,
  dataSubjectId: string,
  dob: string,
  uploadBy: string,
  uploadAtBegin: string,
  uploadAtEnded: string
) {
  const param = new URLSearchParams();
  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }
  if (name !== undefined) {
    param.append("name", name);
  }
  if (dataSubjectId !== undefined) {
    param.append("dataSubjectId", dataSubjectId);
  }
  if (dob !== undefined) {
    param.append("dob", dob);
  }
  if (uploadBy !== undefined) {
    param.append("uploadBy", uploadBy);
  }
  if (uploadAtBegin !== undefined) {
    param.append("uploadAtBegin", uploadAtBegin);
  }
  if (uploadAtEnded !== undefined) {
    param.append("uploadAtEnded", uploadAtEnded);
  }

  return BackendApiUrl.getConsentRecordList + "?" + param.toString();
}

export function GetFilterConsent(
  name: string,
  dataSubjectId: string,
  dob: string,
  uploadBy: string,
  uploadAtBegin: string,
  uploadAtEnded: string
) {
  const param = new URLSearchParams();
  if (name !== undefined) {
    param.append("name", name);
  }
  if (dataSubjectId !== undefined) {
    param.append("dataSubjectId", dataSubjectId);
  }
  if (dob !== undefined) {
    param.append("dob", dob);
  }
  if (uploadBy !== undefined) {
    param.append("uploadBy", uploadBy);
  }
  if (uploadAtBegin !== undefined) {
    param.append("uploadAtBegin", uploadAtBegin);
  }
  if (uploadAtEnded !== undefined) {
    param.append("uploadAtEnded", uploadAtEnded);
  }

  return BackendApiUrl.GetFilterConsent + "?" + param.toString();
}

export function GetExcelConsentRecord(
  name: string,
  dataSubjectId: string,
  dob: string,
  uploadBy: string,
  uploadAtBegin: string,
  uploadAtEnded: string
) {
  const param = new URLSearchParams();
  if (name !== undefined) {
    param.append("name", name);
  }
  if (dataSubjectId !== undefined) {
    param.append("dataSubjectId", dataSubjectId);
  }
  if (dob !== undefined) {
    param.append("dob", dob);
  }
  if (uploadBy !== undefined) {
    param.append("uploadBy", uploadBy);
  }
  if (uploadAtBegin !== undefined) {
    param.append("uploadAtBegin", uploadAtBegin);
  }
  if (uploadAtEnded !== undefined) {
    param.append("uploadAtEnded", uploadAtEnded);
  }

  return BackendApiUrl.getExcelConsentRecord + "?" + param.toString();
}

export function GetDataMappingList(
  itemsPerPage: number,
  page: number,
  personalDataCategoryId: string,
  individualCategoryId: string,
  systemSource: string,
  dataLifecycleStage: string,
  isDataEncrypted: string
) {
  const param = new URLSearchParams();
  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }
  if (personalDataCategoryId !== undefined) {
    param.append("personalDataCategoryId", personalDataCategoryId);
  }
  if (individualCategoryId !== undefined) {
    param.append("individualCategoryId", individualCategoryId);
  }
  if (systemSource !== undefined) {
    param.append("systemSource", systemSource);
  }
  if (dataLifecycleStage !== undefined) {
    param.append("dataLifecycleStage", dataLifecycleStage);
  }
  if (isDataEncrypted !== undefined) {
    param.append("isDataEncrypted", isDataEncrypted);
  }

  return BackendApiUrl.getDataMappingList + "?" + param.toString();
}

export function GetFilterDataMapping(
  personalDataCategoryId: string,
  individualCategoryId: string,
  systemSource: string,
  dataLifecycleStage: string,
  isDataEncrypted: string
) {
  const param = new URLSearchParams();
  if (personalDataCategoryId !== undefined) {
    param.append("personalDataCategoryId", personalDataCategoryId);
  }
  if (individualCategoryId !== undefined) {
    param.append("individualCategoryId", individualCategoryId);
  }
  if (systemSource !== undefined) {
    param.append("systemSource", systemSource);
  }
  if (dataLifecycleStage !== undefined) {
    param.append("dataLifecycleStage", dataLifecycleStage);
  }
  if (isDataEncrypted !== undefined) {
    param.append("isDataEncrypted", isDataEncrypted);
  }

  return BackendApiUrl.GetFilterDataMapping + "?" + param.toString();
}

export function GetExcelDataMapping(
  personalDataCategoryId: string,
  individualCategoryId: string,
  systemSource: string,
  dataLifecycleStage: string,
  isDataEncrypted: string
) {
  const param = new URLSearchParams();
  if (personalDataCategoryId !== undefined) {
    param.append("personalDataCategoryId", personalDataCategoryId);
  }
  if (individualCategoryId !== undefined) {
    param.append("individualCategoryId", individualCategoryId);
  }
  if (systemSource !== undefined) {
    param.append("systemSource", systemSource);
  }
  if (dataLifecycleStage !== undefined) {
    param.append("dataLifecycleStage", dataLifecycleStage);
  }
  if (isDataEncrypted !== undefined) {
    param.append("isDataEncrypted", isDataEncrypted);
  }

  return BackendApiUrl.getExcelDataMapping + "?" + param.toString();
}

export function GetNotificationList(
  itemsPerPage: number,
  page: number,
  search: string,
  date: string,
  userId?: string
) {
  const param = new URLSearchParams();
  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }
  if (date !== undefined) {
    param.append("date", date);
  }
  if (search !== undefined) {
    param.append("search", search);
  }
  if (userId !== undefined) {
    param.append("Id", userId);
  }
  return BackendApiUrl.getNotificationList + "?" + param.toString();
}

export function GetUser(email: string, itemsPerPage: number, page: number) {
  const param = new URLSearchParams();
  if (email !== undefined) {
    param.append("email", email);
  }

  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }

  return BackendApiUrl.getUser + "?" + param.toString();
}

export function GetVendorList(
  itemsPerPage: number,
  page: number,
  vendorType: string,
  name: string,
  vendorResponsibility: string,
  SIUP: string
) {
  const param = new URLSearchParams();
  if (itemsPerPage !== undefined) {
    param.append("itemsPerPage", itemsPerPage.toLocaleString());
  }
  if (page !== undefined) {
    param.append("page", page.toLocaleString());
  }
  if (vendorType !== undefined) {
    param.append("vendorType", vendorType);
  }
  if (name !== undefined) {
    param.append("name", name);
  }
  if (vendorResponsibility !== undefined) {
    param.append("vendorResponsibility", vendorResponsibility);
  }
  if (SIUP !== undefined) {
    param.append("SIUP", SIUP);
  }

  return BackendApiUrl.getVendorList + "?" + param.toString();
}
